<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import store from '@/store/index'

export default {
   components: {
      VuexyLogo,
      ValidationProvider,
      ValidationObserver,
   },
   mixins: [togglePasswordVisibility],
   data() {
      return {
         status: '',
         password: '',
         username: '',
         sideImg: require('@/assets/images/pages/login-v2.svg'),
         // validation rulesimport store from '@/store/index'
         required,
         email,
      }
   },
   computed: {
      isLoading() {
         return this.$store.state.isLoading
      },
      passwordToggleIcon() {
         return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      imgUrl() {
         if (store.state.appConfig.layout.skin === 'dark') {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
            return this.sideImg
         }
         return this.sideImg
      },
   },
   methods: {
      ...mapActions({
         login: 'Authentication/login',
      }),
      validationForm() {
         // console.log('validationForm')
         this.$refs.loginValidation.validate().then(success => {
            // console.log(success)
            if (success) {
               this.login({ username: this.username, password: this.password })
                  .then(response => {
                     this.loading = false
                     this.$ability.update(response.ability)
                     this.$router.replace('/')
                        .then(() => {
                           this.$toast.success('Berhasil Login')
                           // this.$toast({
                           //    component: ToastificationContent,
                           //    props: {
                           //       title: 'Berhasil Login',
                           //       icon: 'CheckIcon',
                           //       variant: 'success',
                           //    },
                           // })
                        })
                  })
                  .catch(() => {
                     // console.log('')
                     this.loading = false
                  })
            }
         })
      },
   },
}
</script>

<template>
   <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
         <!-- Brand logo-->
         <b-link class="brand-logo">
            <vuexy-logo />
            <h2 class="brand-text text-primary ml-1">
               e-Rekap
            </h2>
         </b-link>
         <!-- /Brand logo-->

         <!-- Left Text-->
         <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
               <b-img fluid :src="imgUrl" alt="Login V2" />
            </div>
         </b-col>
         <!-- /Left Text-->

         <!-- Login-->
         <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
            <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
               <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                  Welcome to e-Rekap! 👋
               </b-card-title>
               <b-card-text class="mb-2">
                  Please sign-in to your account and start the adventure
               </b-card-text>

               <!-- form -->
               <validation-observer ref="loginValidation">
                  <b-form class="auth-login-form mt-2" @submit.prevent>
                     <!-- email -->
                     <b-form-group label="Username" label-for="login-username">
                        <validation-provider #default="{ errors }" name="Username" rules="required">
                           <b-form-input
                              id="login-username"
                              v-model="username"
                              :state="errors.length > 0 ? false : null"
                              name="login-username"
                              placeholder="administrator"
                           />
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>

                     <!-- forgot password -->
                     <b-form-group>
                        <div class="d-flex justify-content-between">
                           <label for="login-password">Password</label>
                        </div>
                        <validation-provider #default="{ errors }" name="Password" rules="required">
                           <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                              <b-form-input
                                 id="login-password"
                                 v-model="password"
                                 :state="errors.length > 0 ? false : null"
                                 class="form-control-merge"
                                 :type="passwordFieldType"
                                 name="login-password"
                                 placeholder="············"
                              />
                              <b-input-group-append is-text>
                                 <feather-icon
                                    class="cursor-pointer"
                                    :icon="passwordToggleIcon"
                                    @click="togglePasswordVisibility"
                                 />
                              </b-input-group-append>
                           </b-input-group>
                           <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>

                     <!-- submit buttons -->
                     <b-button
                        type="submit"
                        variant="primary"
                        block
                        :disabled="isLoading"
                        @click.prevent="validationForm"
                     >
                        <b-spinner v-if="isLoading" small /> Sign in
                     </b-button>
                  </b-form>
               </validation-observer>
            </b-col>
         </b-col>
         <!-- /Login-->
      </b-row>
   </div>
</template>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
